<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow: scroll; background-color: #f7f5f9">
		<BHLoading :show="loading" />
		<FilterDrawer />
		<div class="dF aC jSB">
			<h4 class="mb-3 mt-3"> Worksheets </h4>
			<div @click="showFilter" class="dF aC px-3 py-2 ml-3"
				style="background-color:white; border-radius:4px; border:1px solid #ECEBED; cursor:pointer">
				<div class="mr-2">Filters</div>
				<i class="fe fe-filter" />
			</div>
		</div>
		<a-card :bodyStyle="{ padding: 0 }">
			<div class="table">
				<a-table :rowKey="(e) => e.id" :columns="columns" :dataSource="worksheets"
					:loading="loading"
					:pagination="{
                        current: currentPage,
                        total: totalWorksheets,
                        pageSize: pageSize,
                    }"
					@change="handleChange"
					class="white-table" :scroll="{ x: 1100 }">
					<div slot="readableId" slot-scope="worksheet">
						{{ worksheet.readableId }}
					</div>
					<div slot="lot1" slot-scope="worksheet" class="text-center">
						<span
							:style="`${['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && worksheet.data && worksheet.data.lots && worksheet.data.lots[0] === (worksheet.lot && worksheet.lot.id) ? 'font-weight:bold' : ''};`">
							{{ worksheet.data && worksheet.data.lots && worksheet.data.lots.length && worksheet.data.lots[0] &&
								getLotName(worksheet.data.lots[0], worksheet.data.units[0])
							}}
						</span>

					</div>
					<div slot="lot2" slot-scope="worksheet" class="text-center">
						<span
							:style="`${['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && worksheet.data && worksheet.data.lots && worksheet.data.lots[1] === (worksheet.lot && worksheet.lot.id) ? 'font-weight:bold' : ''};`">
							{{ worksheet.data && worksheet.data.lots && worksheet.data.lots.length && worksheet.data.lots[1] &&
								getLotName(worksheet.data.lots[1], worksheet.data.units[1])
							}}
						</span>
					</div>
					<div slot="lot3" slot-scope="worksheet" class="text-center">
						<span
							:style="`${['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && worksheet.data && worksheet.data.lots && worksheet.data.lots[2] === (worksheet.lot && worksheet.lot.id) ? 'font-weight:bold' : ''};`">
							{{ worksheet.data && worksheet.data.lots && worksheet.data.lots.length && worksheet.data.lots[2] &&
								getLotName(worksheet.data.lots[2], worksheet.data.units[2])
							}}
						</span>
					</div>
					<div slot="unit1" slot-scope="worksheet" class="text-center">
						<span
							:style="`${['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && worksheet.data && worksheet.data.units && worksheet.data.units[0] === (worksheet.unit && worksheet.unit.id) ? 'font-weight:bold' : ''};`">
							{{ worksheet.data && worksheet.data.units && worksheet.data.units.length && worksheet.data.units[0] &&
								getUnitNumber(worksheet.data.units[0])
							}}
						</span>

					</div>
					<div slot="unit2" slot-scope="worksheet" class="text-center">
						<span
							:style="`${['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && worksheet.data && worksheet.data.units && worksheet.data.units[1] === (worksheet.unit && worksheet.unit.id) ? 'font-weight:bold' : ''};`">
							{{ worksheet.data && worksheet.data.units && worksheet.data.units.length && worksheet.data.units[1] &&
								getUnitNumber(worksheet.data.units[1])
							}}
						</span>
					</div>
					<div slot="unit3" slot-scope="worksheet" class="text-center">
						<span
							:style="`${['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && worksheet.data && worksheet.data.units && worksheet.data.units[2] === (worksheet.unit && worksheet.unit.id) ? 'font-weight:bold' : ''};`">
							{{ worksheet.data && worksheet.data.units && worksheet.data.units.length && worksheet.data.units[2] &&
								getUnitNumber(worksheet.data.units[2])
							}}
						</span>
					</div>
					<div slot="parking" slot-scope="worksheet" class="text-center">
						<span v-if="worksheet.data && worksheet.data.addons && worksheet.data.addons.parking && worksheet.data.addons.parking.length">{{
							getAddOnNames(worksheet.data.addons.parking, 'parking') }}</span>
					</div>
					<div slot="lockers" slot-scope="worksheet" class="text-center">
						<span v-if="worksheet.data && worksheet.data.addons && worksheet.data.addons.lockers && worksheet.data.addons.lockers.length">{{
							getAddOnNames(worksheet.data.addons.lockers, 'lockers') }}</span>
					</div>
					<div slot="bikeRacks" slot-scope="worksheet" class="text-center">
						<span v-if="worksheet.data && worksheet.data.addons && worksheet.data.addons.bikeRacks && worksheet.data.addons.bikeRacks.length">{{
							getAddOnNames(worksheet.data.addons.bikeRacks, 'bikeRacks') }}</span>
					</div>
					<div slot="purchasers" slot-scope="worksheet">
						{{ getPurchaserNames(worksheet) }}
					</div>
					<div slot="submittedBy" slot-scope="worksheet">
						{{ getAssigneeName(worksheet) }}
					</div>
					<div slot="status" slot-scope="worksheet" class="text-center">
						<span class="badge-offer tT" :class="classLookup(worksheet.status).color">
							{{ classLookup(worksheet.status).text }}
						</span>
					</div>
					<div slot="declineReason" slot-scope="worksheet" class="text-center">
						<span v-if="worksheet.status === 'declined'">{{ worksheet.declineReason }}</span>
					</div>
					<div slot="createdAt" slot-scope="worksheet">
						{{ getDate(worksheet.createdAt) }}
					</div>
					<div slot="updatedAt" slot-scope="worksheet">
						{{ getDate(worksheet.updatedAt) }}
					</div>

					<div slot="actions" slot-scope="worksheet" class="dF jSA">
						<a-popover trigger="hover" placement="bottomRight" overlayClassName="popoverStyle">
							<div slot="content">
								<div class="popoverContent dF aC" @click="editWorksheet(worksheet)">
									<span v-if="isAgent">
										{{ ['pending', 'declined'].includes(worksheet.status) ? "Edit" : "View" }}
									</span>
									<span v-else>
										View
									</span>
								</div>
								<div v-if="!isAgent && !['approved', 'reserved', 'void', 'sold'].includes(worksheet.status)"
									class="popoverContent dF aC" @click="handelApprove(worksheet)">
									Approve
								</div>
								<div v-if="!isAgent && ['approved', 'pending'].includes(worksheet.status) && !worksheet.reservation"
									class="popoverContent dF aC" @click="handelDecline(worksheet)">
									Decline
								</div>
								<div v-if="!isAgent && worksheet.status === 'approved' && !worksheet.reservation"
									class="popoverContent dF aC" @click="handelSendReservationAgreement(worksheet)">
									Send Reservation Agreement
								</div>
								<div v-if="!isAgent && ['reserved'].includes(worksheet.status)" class="popoverContent dF aC"
									@click="createTransaction(worksheet)">
									Make a Transaction
								</div>
								<div v-if="!isAgent" class="popoverContent dF aC" @click="archiveWorksheet(worksheet)">
									Archive
								</div>
								<div v-if="!['approved', 'reserved', 'void', 'sold'].includes(worksheet.status) && worksheet.reservation"
									class="popoverContent dF aC" @click="deleteWorksheet(worksheet)">
									Delete
								</div>
								<div v-if="!isAgent && (['reserved'].includes(worksheet.status) || (!requiredMutualRelease && ['approved'].includes(worksheet.status) && worksheet.reservation && worksheet.reservation.envelops))"
									class="popoverContent dF aC" @click="voidReservation(worksheet)">
									Void Reservation
								</div>
								<div v-if="!isAgent && ['approved'].includes(worksheet.status) && worksheet.reservation && worksheet.reservation.envelops && worksheet.reservation.envelops[0]"
									class="popoverContent dF aC" @click="resendReservation(worksheet)">
									Resend Reservation
								</div>
								<div v-if="worksheet.reservation && worksheet.reservation.envelops && worksheet.reservation.envelops[0]"
									class="popoverContent dF aC" @click="downloadFile(worksheet)">
									Download Agreement
								</div>
							</div>
							<div class="more-option-icon pr-4">
								<a-icon style="line-height: 40px" type="more" />
							</div>
						</a-popover>
					</div>
				</a-table>
			</div>
		</a-card>

		<EnvelopeModal :visible="reservationModal" :worksheet="selectedWorksheet" @close="reservationModal = false;" />

		<a-modal :visible="approveModal" @cancel="approveModal = false;" :centered="true" title="Approve Worksheet"
			okText="APPROVE" @ok="approveWorksheet" :confirmLoading="loadingLotUnits">
			<a-form-model ref="approve" :model="approve" class="profile">
				<a-row type="flex" :gutter="20" v-if="selectedWorksheet">
					<a-col :span="24" v-if="instance.productType === 'lowrise'">
						<a-form-model-item label="Select lot to approve worksheet" required
							:rules="req('Please select lot to approve')" prop="lot">
							<a-select v-model="approve.lot" size="large">
								<a-select-option v-for="lot in validLot.list" :key="lot.id" :value="lot.id"
									:disabled="lot.disabled">
									Lot {{ lot.name }} {{ lot.block ? ` (Block ${lot.block})` : '' }} ({{ statusList[lot.status] || lot.status}})
								</a-select-option>
							</a-select>
						</a-form-model-item>
						<a-alert class="mb-3" v-if="validLot.noValidAvailable" type="error"
							message="No valid available Lots found for the current choice. Please inform agent to update the lot choice(s)."
							show-icon />
						<h6 v-if="loadingLotUnits" class="mt-3">Loading Units ...</h6>
						<a-form-model-item v-else-if="approve.unit && lotUnits && lotUnits.length" label="Selected Model Elevation" required
							:rules="req('Please select model elevation to approve')" prop="unit">
							<a-select :value="approve.unit" size="large" disabled>
								<a-select-option v-for="unit in lotUnits" :key="unit.id" :value="unit.id" disabled>
									{{unit.unitGroup && unit.unitGroup.name}} - <em>{{unit.name}} </em> <span v-if="unit.unitGroup.isStandingInventory"> (Standing Inventory)</span>
								</a-select-option>
							</a-select>
						</a-form-model-item>
						<a-alert class="mb-3" v-if="lotUnitsError" type="error"
							message="No valid available units found for the current choice. Please inform agent to update the unit choice(s)."
							show-icon />
						<a-form-model-item v-if="approve.package && packages && packages.length" label="Selected Floorplan Package" required
							:rules="req('Please select floorplan package to approve')" prop="package">
							<a-select :value="approve.package" size="large" disabled>
								<a-select-option v-for="pkg in packages" :key="pkg.id" :value="pkg.id" disabled>
									{{pkg.name}}
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :span="24" v-else>
						<a-form-model-item label="Select unit to approve worksheet" required
							:rules="req('Please select unit to approve')" prop="unit">
							<a-select v-model="approve.unit" size="large">
								<a-select-option v-for="unit in validUnit.list" :key="unit.id" :value="unit.id"
									:disabled="unit.disabled">
									Unit {{ unit.unitNumber }} ({{ statusList[unit.salesStatus] || unit.salesStatus
									}})
								</a-select-option>
							</a-select>
						</a-form-model-item>
						<a-alert class="mb-3" v-if="validUnit.noValidAvailable" type="error"
							message="No valid available Units found for the current choice. Please inform agent to update the unit choice(s)."
							show-icon />
					</a-col>
					<!-- <a-col :span="24" v-if="packages && packages.length > 1">
						<a-form-model-item label="Select the Floor Plan Package" required
							:rules="req('Please select a Unit Floor Plan Package')" prop="package">
							<a-select v-model="approve.package" size="large">
								<a-select-option :value="item.id" v-for="item in packages" :key="item.id">
									{{item.name}}
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col> -->
                    <template v-if="addOnField">
                        <a-col :span="24">
    						<a-form-model-item label="Select Parking Add ons to approve worksheet"
    							:required="!!(selectedAddOns?.parking?.length)"
    							:rules="req('Please select parking add ons to approve', !!(selectedAddOns?.parking?.length))"
    							prop="addons.parking">
    							<a-select v-model="approve.addons.parking" size="large"
    								:mode="addOnField.parking.allowMultiple ? 'multiple' : 'default'">
    								<a-select-option v-for="addOn in validAddOns.parking.list" :key="addOn.id" :value="addOn.id"
    									:disabled="addOn.disabled">
    									Parking {{ addOn.pID }} #{{ addOn.name }} ({{ statusList[addOn.status] || addOn.status }})
    								</a-select-option>
    							</a-select>
    						</a-form-model-item>
    						<a-alert class="mb-3"
    							v-if="validAddOns.parking.noValidAvailable && !!(selectedAddOns?.parking?.length)"
    							type="error"
    							message="No valid available Parking Addons found for the current choice. Please inform agent to update the Parking Addons choice(s)."
    							show-icon />
    					</a-col>
    					<a-col :span="24">
    						<a-form-model-item label="Select Lockers Add ons to approve worksheet"
    							:required="!!(selectedAddOns?.lockers?.length)"
    							:rules="req('Please select lockers add ons to approve', !!(selectedAddOns?.lockers?.length))"
    							prop="addons.lockers">
    							<a-select v-model="approve.addons.lockers" size="large"
    								:mode="addOnField.lockers.allowMultiple ? 'multiple' : 'default'">
    								<a-select-option v-for="addOn in validAddOns.lockers.list" :key="addOn.id" :value="addOn.id"
    									:disabled="addOn.disabled">
    									Locker {{ addOn.lID }} #{{ addOn.name }} ({{ statusList[addOn.status] || addOn.status }})
    								</a-select-option>
    							</a-select>
    						</a-form-model-item>
    						<a-alert class="mb-3"
    							v-if="validAddOns.lockers.noValidAvailable && !!(selectedAddOns?.lockers?.length)"
    							type="error"
    							message="No valid available Lockers Addons found for the current choice. Please inform agent to update the Lockers Addons choice(s)."
    							show-icon />
    					</a-col>
    					<a-col :span="24">
    						<a-form-model-item label="Select Bike Racks Add ons to approve worksheet"
    							:required="!!(selectedAddOns?.bikeRacks?.length)"
    							:rules="req('Please select bike racks add ons to approve', !!(selectedAddOns?.bikeRacks?.length))"
    							prop="addons.bikeRacks">
    							<a-select v-model="approve.addons.bikeRacks" size="large"
    								:mode="addOnField.bikeRacks.allowMultiple ? 'multiple' : 'default'">
    								<a-select-option v-for="addOn in validAddOns.bikeRacks.list" :key="addOn.id"
    									:value="addOn.id" :disabled="addOn.disabled">
    									Bike Rack {{ addOn.bID }} #{{ addOn.name }} ({{ statusList[addOn.status] || addOn.status }})
    								</a-select-option>
    							</a-select>
    						</a-form-model-item>
    						<a-alert class="mb-3"
    							v-if="validAddOns.bikeRacks.noValidAvailable && !!(selectedAddOns?.bikeRacks?.length)"
    							type="error"
    							message="No valid available Bike Racks Addons found for the current choice. Please inform agent to update the Bike Racks Addons choice(s)."
    							show-icon />
    					</a-col>
                    </template>
                    <template v-if="nonInventoryAddOnField">
                        <a-col :span="24">
    						<a-form-model-item label="Select Non Inventory Parking Add ons to approve worksheet"
    							:required="!!(selectedNonInventoryAddOns?.parking?.length)"
    							:rules="req('Please select parking add ons to approve', !!(selectedNonInventoryAddOns?.parking?.length))"
    							prop="nonInventoryAddOns.parking">
    							<a-select v-model="approve.nonInventoryAddOns.parking" size="large">
    								<a-select-option v-for="addOn in validNonInventoryAddOns.parking.list" :key="addOn.id" :value="addOn.id"
    									:disabled="addOn.disabled">
    									Parking {{ addOn.pID }} #{{ addOn.name }}
    								</a-select-option>
    							</a-select>
    						</a-form-model-item>
    						<a-alert class="mb-3"
    							v-if="validNonInventoryAddOns.parking.noValidAvailable && !!(selectedNonInventoryAddOns?.parking?.length)"
    							type="error"
    							message="No valid non inventory available Parking Addons found for the current choice. Please inform agent to update the Non Inventory Parking Addons choice."
    							show-icon />
    					</a-col>
    					<a-col :span="24">
    						<a-form-model-item label="Select Non Inventory Lockers Add ons to approve worksheet"
    							:required="!!(selectedNonInventoryAddOns?.lockers?.length)"
    							:rules="req('Please select lockers add ons to approve', !!(selectedNonInventoryAddOns?.lockers?.length))"
    							prop="nonInventoryAddOns.lockers">
    							<a-select v-model="approve.nonInventoryAddOns.lockers" size="large">
    								<a-select-option v-for="addOn in validNonInventoryAddOns.lockers.list" :key="addOn.id" :value="addOn.id"
    									:disabled="addOn.disabled">
    									Locker {{ addOn.lID }} #{{ addOn.name }}
    								</a-select-option>
    							</a-select>
    						</a-form-model-item>
    						<a-alert class="mb-3"
    							v-if="validNonInventoryAddOns.lockers.noValidAvailable && !!(selectedNonInventoryAddOns.lockers?.length)"
    							type="error"
    							message="No valid non inventory available Lockers Addons found for the current choice. Please inform agent to update the Non Inventory Lockers Addons choice."
    							show-icon />
    					</a-col>
    					<a-col :span="24">
    						<a-form-model-item label="Select Non Inventory Bike Racks Add ons to approve worksheet"
    							:required="!!(selectedNonInventoryAddOns?.bikeRacks?.length)"
    							:rules="req('Please select bike racks add ons to approve', !!(selectedNonInventoryAddOns?.bikeRacks?.length))"
    							prop="nonInventoryAddOns.bikeRacks">
    							<a-select v-model="approve.nonInventoryAddOns.bikeRacks" size="large">
    								<a-select-option v-for="addOn in validNonInventoryAddOns.bikeRacks.list" :key="addOn.id"
    									:value="addOn.id" :disabled="addOn.disabled">
    									Bike Rack {{ addOn.bID }} #{{ addOn.name }}
    								</a-select-option>
    							</a-select>
    						</a-form-model-item>
    						<a-alert class="mb-3"
    							v-if="validNonInventoryAddOns.bikeRacks.noValidAvailable && !!(selectedNonInventoryAddOns?.bikeRacks?.length)"
    							type="error"
    							message="No valid non inventory available Bike Racks Addons found for the current choice. Please inform agent to update the Non Inventory Bike Racks Addons choice."
    							show-icon />
    					</a-col>
                    </template>

					<a-col :span="24">
						<a-form-model-item prop="reservationAgreementRequired">
							<template slot="label">
								Reservation Agreement Required
								<a-tooltip overlayClassName="change-tooltip-color">
									<template slot="title">
										Select 'Yes' if you want to send the reservation agreement to the purchaser before
										processing the transaction.
										<br>
										<br>
										Select 'No' if you want to process the transaction without a reservation agreement.
									</template>
									<a-icon type="question-circle"
										style="font-size: 14px; color: black; margin-left: 2px;" />
								</a-tooltip>
							</template>
							<a-radio-group v-model="approve.reservationAgreementRequired" size="large">
								<a-radio-button :value="true"> Yes </a-radio-button>
								<a-radio-button :value="false"> No </a-radio-button>
							</a-radio-group>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-modal>

		<a-modal :visible="declineModal" @cancel="declineModal = false;" :centered="true" title="Decline Worksheet"
			okText="DECLINE" @ok="declineWorksheet">
			<a-form-model ref="decline" :model="decline" class="profile" v-if="selectedWorksheet">
				<p>Are you sure you want to decline worksheet <strong>{{ selectedWorksheet.readableId }}</strong>?</p>
				<a-row type="flex" :gutter="20" class="mt-3">
					<a-col :span="24">
						<a-form-model-item label="Decline Reason" :rules="req('Please enter decline reason')" prop="reason">
							<a-textarea size="large" v-model="decline.reason" placeholder="Decline Reason" :rows="3" />
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-modal>

		<a-modal :visible="voidModal" @cancel="closeVoidModal" :centered="true"
			title="Void Reservation" okText="VOID" @ok="voidWorksheetReservation" :confirmLoading="loading">
			<a-form-model ref="reservationCancellation" :model="reservationCancellation" class="profile" v-if="selectedWorksheet">
				<p>Are you sure you want to Void Reservation for Worksheet <strong>{{selectedWorksheet.readableId}}</strong>?</p>
				<div>Doing this will void this entire reservation <br/>This will void any documents that are pending to be signed and remove any supporting documents and also removes any restrictions from the <b>underlying asset</b> and revert it's status to <b><em>Available</em></b>.</div>
				<a-row type="flex" :gutter="20" class="mt-3">
					<a-col :span="24">
						<a-form-model-item label="Reservation Cancellation Reason"
							:rules="req('Please enter reservation cancellation reason')" prop="reason">
							<a-textarea size="large" v-model="reservationCancellation.reason"
								placeholder="Reservation Cancellation Reason" :rows="3" />
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-modal>

		<a-modal :visible="showArchive" :footer="null" centered>
			<template #title>
				Archive Worksheet <strong>{{ selectedWorksheet && selectedWorksheet.readableId }}</strong>
			</template>

			<div>
				<h6>Would you like to add final notes before this worksheet is archived?</h6>
				<a-textarea v-model="archiveNote" class="mt-2" size="large" :auto-size="{ minRows: 3, maxRows: 5 }"/>
			</div>

			<div style="text-align: right;">
				<a-button @click="onClose" class="mt-4 mr-3" size="large">CANCEL</a-button>
				<a-button @click="confirmArchive" type="primary" size="large">ARCHIVE</a-button>
			</div>
		</a-modal>
	</div>
</template>

<script>
import BHLoading from "bh-mod/components/common/Loading";
import moment from "moment";
import FilterDrawer from '@/components/common/FilterDrawer'
import { downloadURL, formatDate } from 'bh-mod'
import EnvelopeModal from '@/components/common/EnvelopeModal'
import _ from "lodash";

export default {
	components: {
		BHLoading,
		FilterDrawer,
		EnvelopeModal
	},
	data() {
		return {
			loading: false,
			loadingLotUnits: false,
			lotUnitsError: false,
			currentPage: 1,
			pageSize: 10,
			reservationModal: false,
			approveModal: false,
			declineModal: false,
			voidModal: false,
			selectedWorksheet: null,
			lotUnits: [],
			packages: [],
			sortedInfo: {
				key: "updatedAt",
				order: "DESC",
			},
			filterQuery: '',
			approve: {
				lot: null,
				unit: null,
				package: null,
				addons: {
					parking: [],
					lockers: [],
					bikeRacks: []
				},
                nonInventoryAddOns: {
                    parking: [],
                    lockers: [],
                    bikeRacks: []
                },
				reservationAgreementRequired: true,
			},
			decline: {
				reason: ''
			},
			reservationCancellation: {
				reason: ''
			},
			statusList: {
				"available": "Available",
				"sold": "Sold",
				"hold": "Hold",
				"soldConditional": "Sold Conditional",
				"conditional": "Conditional",
				"inventory": "Inventory",
				"notreleased": "Not Released",
				"allocated": "Allocated",
				"approved_for_reservation": "Approved For Reservation",
				"reserved": "Reserved"
			},
			resizeObserver: null,
			showArchive: false,
			archiveNote: '',
		};
	},
	computed: {
		instance() {
			return this.$store.state.instance
		},

		user() {
			return this.$store.state.user.user || {};
		},

		columns() {
			let list = [
				{
					title: "ID",
					key: "readableId",
					scopedSlots: { customRender: "readableId" },
					sorter: (a, b) =>
						a.readableId < b.readableId
							? -1
							: a.readableId > b.readableId
								? 1
								: 0,
					width: '85px'
				},
				{
					title: "Unit #1",
					key: "unit1",
					scopedSlots: { customRender: "unit1" },
					sorter: (a, b) => {
						const aa = this.getUnitNumber(a.data.units[0])
						const bb = this.getUnitNumber(b.data.units[0])
						return aa - bb
					}
				},
				{
					title: "Unit #2",
					key: "unit2",
					scopedSlots: { customRender: "unit2" },
					sorter: (a, b) => {
						const aa = this.getUnitNumber(a.data.units[1])
						const bb = this.getUnitNumber(b.data.units[1])
						return aa - bb
					}
				},
				{
					title: "Unit #3",
					key: "unit3",
					scopedSlots: { customRender: "unit3" },
					sorter: (a, b) => {
						const aa = this.getUnitNumber(a.data.units[2])
						const bb = this.getUnitNumber(b.data.units[2])
						return aa - bb
					}
				},
				{
					title: "Parking #",
					key: "parking",
					scopedSlots: { customRender: "parking" },
					sorter: (a, b) => {
						return (a.data && a.data.addons && a.data.addons.parking && a.data.addons.parking.length || 0) - (b.data && b.data.addons && b.data.addons.parking && b.data.addons.parking.length || 0);
					},
				},
				{
					title: "Locker #",
					key: "lockers",
					scopedSlots: { customRender: "lockers" },
					sorter: (a, b) => {
						return (a.data && a.data.addons && a.data.addons.lockers && a.data.addons.lockers.length || 0) - (b.data && b.data.addons && b.data.addons.lockers && b.data.addons.lockers.length || 0);
					},
				},
				{
					title: "Bike Rack #",
					key: "bikeRacks",
					scopedSlots: { customRender: "bikeRacks" },
					sorter: (a, b) => {
						return (a.data && a.data.addons && a.data.addons.bikeRacks && a.data.addons.bikeRacks.length || 0) - (b.data && b.data.addons && b.data.addons.bikeRacks && b.data.addons.bikeRacks.length || 0);
					},
				},
				{
					title: "Purchaser(s)",
					key: "purchasers",
					scopedSlots: { customRender: "purchasers" },
					sorter: (a, b) => {
						const aa = this.getPurchaserNames(a)
						const bb = this.getPurchaserNames(b)
						return aa < bb ? -1 : aa > bb ? 1 : 0;
					}
				},
				{
					title: "Submitted By",
					key: "submittedBy",
					scopedSlots: { customRender: "submittedBy" },
					sorter: (a, b) => {
						const aa = this.getAssigneeName(a)
						const bb = this.getAssigneeName(b)
						return aa < bb ? -1 : aa > bb ? 1 : 0;
					}
				},
				{
					title: "Status",
					key: "status",
					scopedSlots: { customRender: "status" },
					sorter: (a, b) =>
						a.status < b.status
							? -1
							: a.status > b.status
								? 1
								: 0,
				},
				{
					title: "Decline Reason",
					scopedSlots: { customRender: "declineReason" },
					key: "declineReason",
					sorter: (a, b) =>
						a.declineReason < b.declineReason
							? -1
							: a.declineReason > b.declineReason
								? 1
								: 0,
				},
				{
					title: "Date Created",
					key: "createdAt",
					scopedSlots: { customRender: "createdAt" },
					sorter: (a, b) =>
						moment(a.createdAt).format("x") -
						moment(b.createdAt).format("x"),
				},
				{
					title: "Date Updated",
					key: "updatedAt",
					scopedSlots: { customRender: "updatedAt" },
					sorter: (a, b) =>
						moment(a.updatedAt).format("x") -
						moment(b.updatedAt).format("x"),
					defaultSortOrder: 'descend'
				},
				{
					title: "",
					key: "actions",
					scopedSlots: { customRender: "actions" },
					width: '40px'
				}
			]

			if (this.instance.productType === 'lowrise') {
				list.splice(1, 6, ...[
					{
						title: "Lot #1",
						key: "lot1",
						scopedSlots: { customRender: "lot1" },
						sorter: (a, b) => {
							const aa = this.getLotName(a.data.lots[0], a.data.units[0])
							const bb = this.getLotName(b.data.lots[0], b.data.units[0])
							return aa < bb ? -1 : aa > bb ? 1 : 0
						}
					},
					{
						title: "Lot #2",
						key: "lot2",
						scopedSlots: { customRender: "lot2" },
						sorter: (a, b) => {
							const aa = this.getLotName(a.data.lots[1], a.data.units[1])
							const bb = this.getLotName(b.data.lots[1], b.data.units[1])
							return aa < bb ? -1 : aa > bb ? 1 : 0
						}
					},
					{
						title: "Lot #3",
						key: "lot3",
						scopedSlots: { customRender: "lot3" },
						sorter: (a, b) => {
							const aa = this.getLotName(a.data.lots[2], a.data.units[2])
							const bb = this.getLotName(b.data.lots[2], b.data.units[2])
							return aa < bb ? -1 : aa > bb ? 1 : 0
						}
					},
				])
			}

			return list
		},

		search() {
			return this.$store.state.globalSearch;
		},

		isAgent() {
			return this.$userType === 'agent'
		},

		lots() {
			return this.$store.state.appData.lots.filter(l => l.type === 'lot');
		},

		units() {
			return this.$store.state.appData.units;
		},

		unitNumberMapping() {
			let mapping = {}
			this.units.forEach(u => {
				mapping[u.id] = u.unitNumber
			})

			return mapping;
		},

		worksheets() {
			return this.$store.state.appData.worksheets
		},

		totalWorksheets() {
			return this.$store.state.appData.totalWorksheets || 0;
		},

		filter() {
			return this.$store.state.appData.filterDrawer.filter
		},

		validLot() {
			const result = {
				list: [],
				noValidAvailable: true
			}
			if (this.selectedWorksheet && this.selectedWorksheet.data && this.selectedWorksheet.data.lots) {
				const selectedLots = this.selectedWorksheet.data.lots.filter(l => l).map(l => {
					const lot = this.lots.find(ll => ll.id == l)
					if (lot) {
						if ((lot.status === 'available' || (lot.status === 'allocated' && (lot.allocatedTo && lot.allocatedTo.id) === (this.selectedWorksheet.submittedBy && this.selectedWorksheet.submittedBy.id)))) {
							result.noValidAvailable = false;
							return {
								...lot,
								disabled: false,
							}
						} else {
							return {
								...lot,
								disabled: true,
							}
						}
					}
					return;
				})
				result.list = selectedLots;
			}

			return result;
		},

		validUnit() {
			const result = {
				list: [],
				noValidAvailable: true
			}
			if (this.selectedWorksheet && this.selectedWorksheet.data && this.selectedWorksheet.data.units) {
				const selectedUnits = this.selectedWorksheet.data.units.filter(u => u).map(u => {
					const unit = this.units.find(uu => uu.id == u)
					if (unit) {
						if ((unit.salesStatus === 'available' || (unit.salesStatus === 'allocated' && (unit.allocatedTo && unit.allocatedTo.id) === (this.selectedWorksheet.submittedBy && this.selectedWorksheet.submittedBy.id)))) {
							result.noValidAvailable = false;
							return {
								...unit,
								disabled: false,
							}
						} else {
							return {
								...unit,
								disabled: true,
							}
						}
					}
					return;
				})
				result.list = selectedUnits;
			}

			return result;
		},

		validAddOns() {
			let validAddOns = {
				parking: { list: [], noValidAvailable: true },
				lockers: { list: [], noValidAvailable: true },
				bikeRacks: { list: [], noValidAvailable: true },
			}

			if (this.selectedWorksheet?.data?.addons) {
				Object.keys(this.addOns).forEach(addOn => {
					const selectedAddOns = this.selectedWorksheet.data.addons[addOn].map(a => {
						const addOnFound = this.addOns[addOn].find(aa => aa.id == a)
						if (addOnFound) {
							if ((addOnFound.status === 'available' || (addOnFound.status === 'allocated' && addOnFound.allocatedTo === (this.selectedWorksheet?.submittedBy?.id)))) {
								validAddOns[addOn].noValidAvailable = false;
								return {
									...addOnFound,
									disabled: false,
								}
							} else {
								return {
									...addOnFound,
									disabled: true,
								}
							}
						}
						return;
					});
					validAddOns[addOn].list = selectedAddOns;
				})
			}

			return validAddOns
		},

        validNonInventoryAddOns() {
            let validNonInventoryAddOns = {
                parking: { list: [], noValidAvailable: true },
                lockers: { list: [], noValidAvailable: true },
                bikeRacks: { list: [], noValidAvailable: true },
            }

            if (this.selectedWorksheet?.data?.nonInventoryAddOns) {
                Object.keys(this.nonInventoryAddOns).forEach(addOn => {
                    const addOnFound = this.nonInventoryAddOns[addOn].find(aa => aa.id == this.selectedWorksheet.data.nonInventoryAddOns[addOn])
                    if (addOnFound) {
                        validNonInventoryAddOns[addOn].noValidAvailable = false;
                        validNonInventoryAddOns[addOn].list = [{
                            ...addOnFound,
                            disabled: false,
                        }];
                    }
                })
            }

            return validNonInventoryAddOns
        },

		addOns() {
			return this.$store.state.appData.addOns
		},
        nonInventoryAddOns() {
			return this.$store.state.appData.nonInventoryAddOns
		},
		contractTemplates() {
			return this.$store.state.appData.contractTemplates || []
		},
		selectedAddOns() {
			return { parking: [], lockers: [], bikeRacks: [], ...(this.selectedWorksheet.data.addons || {}) }
		},
        selectedNonInventoryAddOns() {
			return { parking: [], lockers: [], bikeRacks: [], ...(this.selectedWorksheet.data.nonInventoryAddOns || {}) }
		},
		worksheetForms() {
			return this.$store.state.appData.worksheetForms || []
		},
		selectedWorksheetForm() {
			if (this.selectedWorksheet && this.selectedWorksheet.form && this.selectedWorksheet.form.id) {
				return this.worksheetForms.find(w => w.id === this.selectedWorksheet.form.id)
			}
			return false
		},
		addOnField() {
			if (this.selectedWorksheetForm) {
				return this.selectedWorksheetForm.fields.find(f => f.type === 'addOns' && !f.hide)
			}
			return false
		},
        nonInventoryAddOnField() {
			if (this.selectedWorksheetForm) {
				return this.selectedWorksheetForm.fields.find(f => f.type === 'nonInventoryAddOns' && !f.hide)
			}
			return false
		},

		requiredMutualRelease() {
			return this.$store.state.appData.requiredMutualRelease
		},
	},
	watch: {
        search() {
            this.searchDebounce();
        },
		filter: {
			deep: true,
			handler(filter) {
				this.filterQuery = ''
				if (filter.status[0] !== "any") {
					filter.status.forEach(status => {
						this.filterQuery += `&status_in[]=${status}`
					})
				}
				if(filter.lots.length !== 0) {
					filter.lots.forEach(lot => {
						this.filterQuery += `&lot_in[]=${lot}`
					})
				} else if(filter.units.length !== 0) {
					filter.units.forEach(unit => {
						this.filterQuery += `&unit_in[]=${unit}`
					})
				}
				this.fetchWorksheetDetails()
			}
		},
		'approve.lot': {
			async handler(lotId) {
				if (lotId && this.selectedWorksheet && this.selectedWorksheet.data && this.selectedWorksheet.data.lots && this.selectedWorksheet.data.lots.length) {
					let lotIndex = this.selectedWorksheet.data.lots.findIndex(l => l === lotId)
					if (lotIndex > -1) {
						if (this.selectedWorksheet.data.units && this.selectedWorksheet.data.units.length) {
							this.lotUnits = [];
							this.approve.unit = this.selectedWorksheet.data.units[lotIndex] || null
						}
						if (this.selectedWorksheet.data.packages && this.selectedWorksheet.data.packages.length) {
							this.packages = [];
							this.approve.package = this.selectedWorksheet.data.packages[lotIndex] || null
						}
						await this.fetchLotUnits(lotId)
					}
				} else {
					this.approve.unit = null;
					this.approve.package = null;
				}
			}
		}
    },
	created() {
		this.fetchWorksheetDetails();
		this.$store.dispatch('FETCH_CONTRACT_TEMPLATE');
		if (this.instance.productType === 'lowrise') {
			this.$store.dispatch('FETCH_LOTS')
		} else {
			this.$store.dispatch('FETCH_UNITS')
		}
	},
	methods: {
		req: (msg, required = true) => ({ required: required, message: msg }),

		searchDebounce: _.debounce(function () {
			this.fetchWorksheetDetails()
        }, 1000),

		fetchWorksheetDetails() {
			this.currentPage = 1
            this.getWorksheets();
            this.getTotalWorksheetCount();
		},

		async getTotalWorksheetCount() {
            try {
                let searchQuery = "?archived=false";
                if (this.search) {
                    searchQuery += `&_q=${this.search}`;
                }
                let { data } = await this.$api.get(
                    `worksheets/:instance/count${searchQuery}${this.filterQuery}`
                );
				this.$store.commit('SET_WORKSHEET_COUNT', data)
            } catch (error) {
                console.error('Error while fetching worksheet count', error);
            }
        },
        async getWorksheets() {
            this.loading = true;
            try {
                let start = (this.currentPage - 1) * this.pageSize;
                let searchQuery = "";
                if (this.search) {
                    searchQuery = `&_q=${this.search}`;
                }
                const { data } = await this.$api.get(
                    `/worksheets/:instance?archived=false${searchQuery}${this.filterQuery}&_start=${start}&_limit=${this.pageSize}&_sort=${this.sortedInfo.key}:${this.sortedInfo.order}`
                );
                this.$store.commit('SET_WORKSHEETS', data)
            } catch (error) {
                console.error('Error occurred while fetching worksheet list', error);
            }
            this.loading = false;
        },

		handelApprove(worksheet) {
			this.selectedWorksheet = worksheet;
			this.approve.lot = null;
			this.approve.unit = null;
			this.approve.package = null;
			this.approveModal = true;
			this.$nextTick(() => {
				if (this.$refs.approve) {
					this.$refs.approve.resetFields();
					this.$refs.approve.clearValidate();
				}
			})
		},

		handelDecline(worksheet) {
			this.selectedWorksheet = worksheet;
			this.decline.reason = null;
			this.declineModal = true;
			this.$nextTick(() => {
				if (this.$refs.decline) {
					this.$refs.decline.resetFields();
					this.$refs.decline.clearValidate();
				}
			})
		},

		handelSendReservationAgreement(worksheet) {
			this.selectedWorksheet = worksheet;
			this.reservationModal = true;
		},

		processTransactionConfirmation(worksheet) {
			let self = this;
			this.$confirm({
				title: "Process Transaction",
				content: (h) => <div>Process transaction for worksheet <strong>{worksheet.readableId}</strong> now?</div>,
				okText: "CONTINUE",
				cancelText: "later",
				centered: true,
				onOk: () => {
					self.createTransaction(worksheet);
				},
			});
		},

		processReservationAgreementConfirmation(worksheet) {
			let self = this;
			this.$confirm({
				title: "Process Reservation Agreement",
				content: (h) => <div>Process reservation agreement for worksheet <strong>{worksheet.readableId}</strong> now?</div>,
				okText: "CONTINUE",
				cancelText: "later",
				centered: true,
				onOk: () => {
					self.handelSendReservationAgreement(worksheet)
				},
			});
		},

		async approveWorksheet() {
			if(this.loadingLotUnits) return;
			let self = this;
			this.$refs.approve.validate(async (valid) => {
				if (valid) {
					try {
						self.loadingLotUnits = true;
						let payload = {
							reservationAgreementRequired: self.approve.reservationAgreementRequired
						}

						if (self.instance.productType === 'lowrise') {
							payload = {
								...payload,
								lot: self.approve.lot,
								unit: self.approve.unit,
								package: self.approve.package
							}
						} else {
							let found = self.validUnit.list.find(u => u.id === self.approve.unit);
							if (found) {
								self.packages = found.packages;
								self.approve.package = found.packages[0] || null
							}

							payload = {
								...payload,
								lot: self.approve.lot,
								unit: self.approve.unit,
								package: self.approve.package,
								addons: self.approve.addons,
                                nonInventoryAddOns: self.approve.nonInventoryAddOns
							}
						}

						const { data } = await self.$api.post(`/worksheets/${self.instance.id}/${self.selectedWorksheet.id}/approved`, { ...payload });
						self.approveModal = false;
						const fieldData = {}
						if (data.data) {
							data.data.fields.forEach(f => {
								fieldData[f.key] = f.value;
							})
						}
						self.selectedWorksheet = {
							...data,
							data: fieldData
						};
						self.$message.success(`Selected ${self.instance.productType === 'lowrise' ? 'Lot' : 'Unit and Addons are'} approved successfully.`);
						if (self.approve.reservationAgreementRequired) {
							self.processReservationAgreementConfirmation(self.selectedWorksheet);
						} else {
							self.processTransactionConfirmation(self.selectedWorksheet);
						}
						self.approve.lot = null;
						self.approve.unit = null;
						self.approve.package = null;
						self.approve.addons = {
							parking: [],
							lockers: [],
							bikeRacks: []
						}
                        self.approve.nonInventoryAddOns = {
                            parking: [],
                            lockers: [],
                            bikeRacks: []
                        }
						self.packages = [];
						self.loadingLotUnits = false;
						self.fetchWorksheetDetails()
						if (self.instance.productType === 'lowrise') {
							self.$store.dispatch('FETCH_LOTS')
						} else {
							self.$store.dispatch('FETCH_UNITS')
						}
					} catch (err) {
						self.loadingLotUnits = false;
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err, "Error occurred while updating worksheet status. Please Try Again!"))
						}
					}
				} else {
					return;
				}
			})
		},

		async declineWorksheet() {
			let self = this;
			this.$refs.decline.validate(async (valid) => {
				if (valid) {
					self.loading = true;
					try {
						await self.$api.post(`/worksheets/${self.instance.id}/${self.selectedWorksheet.id}/declined`, { declineReason: self.decline.reason });
						self.declineModal = false;
						self.selectedWorksheet = null;
						self.decline.reason = null;
						self.fetchWorksheetDetails()
						self.$store.dispatch('FETCH_UNITS')
					} catch (err) {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err, "Error occurred while updating worksheet status. Please Try Again!"))
						}
					}
					self.loading = false;
				} else {
					return;
				}
			})
		},

		deleteWorksheet(worksheet) {
			let self = this;
			this.$confirm({
				title: "Delete Worksheet",
				content: (h) => <div>Are you sure you want to delete worksheet <strong>{worksheet.readableId}</strong>?</div>,
				okText: "DELETE",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk: async () => {
					try {
						await self.$api.delete(`/worksheets/${self.instance.id}/${worksheet.id}`);
						self.fetchWorksheetDetails();
						self.$store.dispatch('FETCH_UNITS')
					} catch (err) {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err, "Error occurred while deleting worksheet. Please Try Again!"))
						}
					}
				},
			});
		},

		voidReservation(worksheet) {
			if (this.$p < 40) return this.$message.error('You do not have permission to void this worksheet reservation')

			if (this.requiredMutualRelease && worksheet.reservation && worksheet.reservation.envelops && worksheet.reservation.envelops[0] && worksheet.reservation.envelops[0].status === 'completed') {
				return this.$notification.error({
					message: 'Cannot Void Signed Reservation',
					description: 'Until you have a signed Notice of Mutual Release. Create a Notice of Mutual Release to void this worksheet reservation in the Documents tab of the worksheet.',
				})
			}

			this.selectedWorksheet = worksheet;
			this.reservationCancellation.reason = '';
			this.voidModal = true;
		},

		async voidWorksheetReservation() {
			let self = this
			this.$refs.reservationCancellation.validate(async (valid) => {
				if (valid) {
					self.loading = true;
					self.$api.post(`/worksheets/${self.instance.id}/${self.selectedWorksheet.id}/void-reservation`, { reason: self.reservationCancellation.reason }).then(() => {
						self.$refs.reservationCancellation.resetFields()
						self.voidModal = false;
						self.$message.success('Selected Worksheet Reservation voided successfully')

						self.fetchWorksheetDetails();
						self.$store.dispatch('FETCH_UNITS')
					}).catch((err) => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err, `An error occurred while voiding reservation. Please try again.`))
						}
					}).finally(() => self.loading = false);
				}
			})
		},

		async fetchLotUnits(lotId) {
			this.lotUnitsError = false;
			this.loadingLotUnits = true;
			try {
				const { data } = await this.$api.get(`/architectural-controls/:instance/availableunits/${lotId}`)
				let units = data.units && data.units.filter(unit => {
					return unit.unitGroup && unit.unitGroup.id && unit.packages && unit.packages.length
				}) || [];
				if (units.length === 0) {
					this.loadingLotUnits = false;
					this.lotUnitsError = true;
				}

				this.lotUnits = units
				let unit = this.lotUnits.find(u => u.id === this.approve.unit)
				if (unit) {
					this.packages = unit.packages
				}
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'Error while fetching unit list. Please select another unit'))
				}
			} finally {
				this.loadingLotUnits = false;
			}
		},

		closeVoidModal() {
			this.$refs.reservationCancellation.resetFields();
			this.selectedWorksheet = null;
			this.voidModal = false;
		},

		editWorksheet(worksheet) {
			const worksheetForm = worksheet.form || (this.worksheetForms && this.worksheetForms.length && this.worksheetForms[0]) || null
			this.$store.commit('SET_WORKSHEET_FORM', worksheetForm)
			this.$router.push(`/edit/${worksheet.id}`)
		},

		createTransaction(worksheet) {
			window.open(`https://transactions.bildhive.${this.$tld}/new?worksheet=${worksheet.id}`, "_blank")
		},

		getPurchaserNames(worksheet) {
			return worksheet.data.purchasers && worksheet.data.purchasers.filter(p => p.email).map(p => p.firstName + " " + p.lastName).join(',\n')
		},

		getAssigneeName(worksheet) {
			return worksheet.submittedBy && worksheet.submittedBy.firstName + " " + worksheet.submittedBy.lastName;
		},

		getDate(date) {
			return moment(date).format("DD/M/YYYY");
		},

		showFilter() {
			this.$store.commit('OPEN_FILTER')
		},

		getLotName(lotId, unitId) {
			const lot = this.lots.find(l => l.id === lotId);

			if (lot) {
				const unit = lot.units.find(u => u.id === unitId);

				if (unit) {
					return `Lot ${lot && lot.name}${lot?.block ? ` (Block ${lot.block})` : ''} (${unit.unitGroup && unit.unitGroup.name} - ${unit.name})`;
				} else {
					return `Lot ${lot && lot.name}${lot?.block ? ` (Block ${lot.block})` : ''}`
				}
			}
			return ''
		},

		getUnitNumber(unitId) {
			const unit = this.units.find(u => u.id === unitId);

			return unit && unit.unitNumber || ''
		},

		getAddOnNames(ids, type = 'parking') {
			let names = '';
			ids.forEach(id => {
				const addon = this.addOns[type].find(a => a.id === id)
				if (addon) {
					names += names ? ", " + addon.name : addon.name;
				}
			})

			return names
		},

        getNonInventoryAddOnNames(ids, type = 'parking') {
			let names = '';
			ids.forEach(id => {
				const addon = this.nonInventoryAddOns[type].find(a => a.id === id)
				if (addon) {
					names += names ? ", " + addon.name : addon.name;
				}
			})

			return names
		},

		async downloadFile(worksheet) {
			this.loading = true;
			let key
			let record = worksheet.reservation && worksheet.reservation.envelops && worksheet.reservation.envelops[0]
			if (record.isManual) {
				if (!record.file || !record.file[0] || !record.file[0].key) {
					this.loading = false;
					return this.$message.error('Cannot find signed PDF file')
				}
				key = record.file[0].key
			} else {
				if (!record.download || !record.download.archive || !record.download.archive.key) {
					this.loading = false;
					return this.$message.error('Cannot find signed PDF file')
				}
				key = record.download.archive.key
			}

			try {
				let { data: url } = await this.$api.get(`/contract-templates/:instance/getfile?key=${key}`)
				await downloadURL(url, `Worksheet ${worksheet.readableId}`)
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, "Error occurred while downloading the file. Please try again!"))
				}
			}
			this.loading = false;
		},

		classLookup(status) {
			let lookup = {
				pending: 'bg-med-gray text-white',
				approved: 'bg-teal text-white',
				reserved: 'bg-dark-green text-white',
				declined: 'bg-red text-white',
				void: 'bg-red text-white',
				draft: 'bg-gray text-white',
				sold: 'bg-pink text-white',
			}
			let textLookup = {
				void: 'Voided'
			}
			return {
				color: lookup[status] || 'badge',
				text: textLookup[status] || status,
			}
		},

		updateScrollVisibility() {
			let scrollContent = document.querySelector('div.ant-table-body');
			let scrollLeftButton = document.querySelector('.scroll-left')
			let scrollRightButton = document.querySelector('.scroll-right')

			if (scrollContent && scrollLeftButton && scrollRightButton) {
				if (scrollContent.offsetWidth < (+scrollContent.scrollWidth - 15)) {
					scrollLeftButton.classList.remove('hide');
					scrollRightButton.classList.remove('hide');
					scrollContent.classList.add('mx-3');
				} else {
					scrollLeftButton.classList.add('hide');
					scrollRightButton.classList.add('hide');
					scrollContent.classList.remove('mx-3');
				}
			}
		},

		resendReservation(worksheet) {
			let envelope = worksheet.reservation.envelops[0]
			this.$confirm({
				title: `This will resend the envelope to all the recipients.`,
				okText: 'RESEND',
				okType: 'primary',
				centered: true,
				onOk: () => {
					this.$store.commit('LOAD', true)
					this.$api.post(`/envelops/:instance/${envelope.id}/resend`)
						.then(() => {
							this.$store.dispatch('FETCH_WORKSHEET', worksheet.id);
							this.$store.commit('LOAD', false)
							return this.$message.success('Successfully resent the envelope to all the recipients!')
						})
						.catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err, 'An error occurred while resending. Please try again!'))
							}
						})
						.finally(() => this.$store.commit('LOAD', false))
				},
			})
		},

		archiveWorksheet(worksheet) {
			this.showArchive = true
			this.selectedWorksheet = worksheet
			this.archiveNote = ''
		},

		onClose() {
			this.showArchive = false
			this.selectedWorksheet = {}
			this.archiveNote = ''
		},

		confirmArchive() {
			let { notes } = this.selectedWorksheet
			if (!notes) notes = []

			notes.push({
				subject: 'Archived on:  ' + formatDate(Date.now()),
				content: this.archiveNote,
				updatedAt: Date.now(),
				id: Date.now() + '',
				createdBy: this.user.id || '',
			})

			this.$api.put(`/worksheets/:instance/${this.selectedWorksheet.id}`, { archived: true, notes }).then(() => {
				this.fetchWorksheetDetails()
				this.onClose();
			}).catch((err) => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
		},

		handleChange(pagination, filter, sorter) {
            if (pagination) {
                this.currentPage = pagination.current;
            }
            if (sorter && sorter.column) {
                this.sortedInfo = {
                    key: sorter.columnKey,
                    order: sorter.order === "descend" ? "DESC" : "ASC",
                };
            }
            this.getWorksheets();
        },
	},

	mounted() {
		this.$nextTick(() => {
			const scrollableArea = document.querySelector('div.ant-table-body')

			var scrollLeftButton = document.createElement('div');
			var scrollRightButton = document.createElement('div');

			scrollLeftButton.className = 'scroll-button scroll-left';
			scrollLeftButton.innerHTML = '&lt;';
			scrollableArea.appendChild(scrollLeftButton);

			scrollRightButton.className = 'scroll-button scroll-right';
			scrollRightButton.innerHTML = '&gt;';
			scrollableArea.appendChild(scrollRightButton);


			scrollLeftButton.addEventListener('click', function () {
				const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
				scrollableArea.scrollBy({
					left: -scrollSize,
					behavior: "smooth",
				})
			});

			scrollRightButton.addEventListener('click', function () {
				const scrollSize = Math.floor(scrollableArea.clientWidth - (scrollableArea.clientWidth * 0.1))
				scrollableArea.scrollBy({
					left: scrollSize,
					behavior: "smooth",
				})
			});

			this.updateScrollVisibility();
		})

		let scrollableArea = document.querySelector('div.ant-table-body');
		this.resizeObserver = new ResizeObserver(() => {
			window.requestAnimationFrame(() => {
				this.updateScrollVisibility()
			})
		});
		this.resizeObserver.observe(scrollableArea);

		// Remove the previous event listener, if any, to prevent multiple bindings
		window.removeEventListener('resize', this.updateScrollVisibility);

		// Attach the updated event listener
		window.addEventListener('resize', this.updateScrollVisibility)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateScrollVisibility)
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
	},

};
</script>

<style lang="scss" scoped>
.template-list i {
	font-size: 25px;
	padding: 6px;
	background: var(--light-purple);
	border-radius: 50%;
	width: 15px;
	height: 15px;
}

.popoverContent {
	height: max-content;
	line-height: 30px;
	padding: 0 10px;
}

.popoverContent:hover {
	background-color: var(--off-white-light);
	color: var(--primary);
	cursor: pointer;
}
</style>

<style lang="scss">
.popoverStyle .ant-popover-inner-content {
	padding: 5px;
	background-color: white;
	border-radius: 20px;
}
</style>
